import React from "react";
import contractIcon from "../img/Contrato.svg";
import automotiveIcon from "../img/Automotriz.svg";
import marineIcon from "../img/Marina.svg";
import protectiveIcon from "../img/Protección_y_vestuario.svg";
import footwearIcon from "../img/Zapatería_y_Marroquinería.svg";
import { FormattedMessage } from "react-intl";

const MARKET_SEGMENT_VALUES = {
  ADMINISTRATION: "ADMINISTRATION",
  COMMUNICATION: "COMMUNICATION",
  CONSTRUCTION_AND_AGRICULTURE: "CONSTRUCTION AND AGRICULTURE",
  CONTRACT_AND_RESIDENTIAL: "CONTRACT AND RESIDENTIAL",
  FOOTWEAR_AND_LEATHER_GOODS: "FOOTWEAR AND LEATHER GOODS",
  MARINE: "MARINE",
  PROTECTION_AND_APPAREL: "PROTECTION AND APPAREL",
  TRANSPORT: "TRANSPORT",
  WRAPPING_AND_PACKAGING: "WRAPPING AND PACKAGING",
};

export const MARKET_SEGMENTS = [
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.segment.option.contract"
        key="contract"
        defaultMessage="Contract"
      />
    ),
    value: MARKET_SEGMENT_VALUES.CONTRACT_AND_RESIDENTIAL,
    icon: contractIcon,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.segment.option.automotive"
        key="automotive"
        defaultMessage="Automotive"
      />
    ),
    value: MARKET_SEGMENT_VALUES.TRANSPORT,
    icon: automotiveIcon,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.segment.option.marine"
        key="marine"
        defaultMessage="Marine"
      />
    ),
    value: MARKET_SEGMENT_VALUES.MARINE,
    icon: marineIcon,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.segment.option.footwear"
        key="footwear"
        defaultMessage="Footwear and leather goods"
      />
    ),
    value: MARKET_SEGMENT_VALUES.FOOTWEAR_AND_LEATHER_GOODS,
    icon: footwearIcon,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.segment.option.protective"
        key="protective"
        defaultMessage="Protective and apparel"
      />
    ),
    value: MARKET_SEGMENT_VALUES.PROTECTION_AND_APPAREL,
    icon: protectiveIcon,
  },
  // {
  //   label: "ADMINISTRATION",
  //   value: MARKET_SEGMENT_VALUES.ADMINISTRATION,
  // },
  // {
  //   label: "COMMUNICATION",
  //   value: MARKET_SEGMENT_VALUES.COMMUNICATION,
  // },
  // {
  //   label: "CONSTRUCTION_AND_AGRICULTURE",
  //   value: MARKET_SEGMENT_VALUES.CONSTRUCTION_AND_AGRICULTURE,
  // },
  // {
  //   label: "WRAPPING_AND_PACKAGING",
  //   value: MARKET_SEGMENT_VALUES.WRAPPING_AND_PACKAGING,
  // },
];
