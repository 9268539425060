import { CATEGORIES_OPTIONS_TO_SEARCH } from "../../../constants/categoriesOptionsToSearch"

export const handleStateByUrlParam = (urlParam, label) => {
  const stateByUrlParam = {
    [CATEGORIES_OPTIONS_TO_SEARCH[1].urlParam]: 
      JSON.stringify({ 
        businessData: { 
          instanceLabelTitle: label 
        } 
      }),
    [CATEGORIES_OPTIONS_TO_SEARCH[0].urlParam]:
      JSON.stringify({ 
        marketSegmentData: { 
          segmentTitle: label 
        } 
      }),
    [CATEGORIES_OPTIONS_TO_SEARCH[2].urlParam]:
      JSON.stringify({ 
        searchByTexture: { 
          textureTitle: label 
        } 
      })
  }

  return stateByUrlParam[urlParam];
}