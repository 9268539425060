import classNames from 'classnames';
import React from 'react'
import { LG } from 'scenes/ProductDetails/components/ProductSpecs/components/OtherAttributesAccordion/constants/breakpoints';
import useMediaQuery from 'utils/hooks/useMediaQuery';

const RenderCertificateImg = ({
  link, 
  sustainabilityProperty, 
  fieldWithExpirateCertification,
  notHaveCertificate
}) => {
  const isMobile = useMediaQuery(LG);
  const fieldExpiredData = fieldWithExpirateCertification?.props?.expiredData
  const { formattedExpiresDate = null } = fieldExpiredData ?? {}

  const heightCertificateImgContainer = { heihgt: !notHaveCertificate ? "100px" : "auto" }

  return (
    <div 
      className={classNames({
        'd-flex': true,
        "flex-row justify-content-center align-items-start": isMobile,
        "flex-column justify-content-center mr-3 align-items-center": !isMobile,
        "pr-3": !fieldExpiredData
      })}
      style={heightCertificateImgContainer}
    >
      {!notHaveCertificate 
      ? 
        <a 
          className="mx-auto d-flex align-items-center" 
          href={link + sustainabilityProperty.certificate} 
          style={{ height: "74px" }}>
          <img
            src={link + sustainabilityProperty.logoImg}
            width={70}
          />
        </a> 
      : 
        <img
          src={link + sustainabilityProperty.logoImg}
          width={70}
        />
      }
      {!notHaveCertificate && formattedExpiresDate && 
        <div className={classNames({
          'mt-2 d-flex': true,
          "align-items-end": !isMobile,
          "align-items-start": isMobile
        })}
          style={{ height: "30px" }}>
          {fieldWithExpirateCertification}
        </div>}
    </div>
  )
}

export default RenderCertificateImg