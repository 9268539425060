const ATTRIBUTES = "attributes"
const COMPLIANCES = "compliances"
const SEALS_WE_CONTRIBUTE_TO = "seals"
const PACKAGING = "packaging"

export const CERTIFICATIONS = "certifications"
export const MANUFACTURING = "manufacturing"

export const SUSTAINABILITY_ATTRIBUTES = {
  [ATTRIBUTES]: {
    biobasedPvcBioAttributed: "biobasedPvcBioAttributed",
    phthalateFreeProduct: "phthalateFreeProduct",
    phthalateFreeProductCertificateFile: "phthalateFreeProductCertificateFile",
    phthalateFreeProductCertificateFileExpires: "phthalateFreeProductCertificateFileExpires",
    phthalateFreeProductNotExpires: "phthalateFreeProductNotExpires",
    frFree: "frFree",
    frFreeCertificateFile: "frFreeCertificateFile",
    frFreeCertificateFileExpires: "frFreeCertificateFileExpires",
    frFreeNotExpires: "frFreeNotExpires",
    mpFree: "mpFree",
    mpFreeCertificateFile: "mpFreeCertificateFile",
    mpFreeCertificateFileExpires: "mpFreeCertificateFileExpires",
    mpFreeNotExpires: "mpFreeNotExpires",
    cytotoxicity: "cytotoxicity",
    cytotoxicityCertificateFile: "cytotgenericCertificateFile",
    cytotoxicityCertificateFileExpires: "cytotoxicityCertificateFileExpires",
    cytotoxicityNotExpires: "cytotoxicityNotExpires",
    skinSafeSensitization: "skinSafeSensitization",
    skinSafeSensitizationCertificateFile: "skinSafeSensitizationCertificateFile",
    skinSafeSensitizationCertificateFileExpires: "skinSafeSensitizationCertificateFileExpires",
    skinSafeSensitizationNotExpires: "skinSafeSensitizationNotExpires",
    antimicrobial: "antimicrobial",
    epd: "epd",
    epdCertificateFile: "epdCertificateFile",
    epdNotExpires: "epdNotExpires",
    backingProfile: "backingProfile",
    backingProfileLogo: "backingProfileLogo"
  },
  [COMPLIANCES]: {
    prop65: "prop65",
    prop65CertificateFile: "prop65CertificateFile",
    prop65CertificateFileExpires: "prop65CertificateFileExpires",
    prop65NotExpires: "prop65NotExpires",
    prop65Logo: "prop65Logo",
    rohs: "rohs",
    rohsLogo: "rohsLogo",
    rohsCertificateFile: "rohsCertificateFile",
    rohsCertificateFileExpires: "rohsCertificateFileExpires",
    rohsNotExpires: "rohsNotExpires",
    reach: "reach",
    rohsCertificateFile: "rohsCertificateFile",
    rohsCertificateFileExpires: "rohsCertificateFileExpires",
    rohsNotExpires: "rohsNotExpires",
    reachLogo: "reachLogo",
    reachCertificateFile: "reachCertificateFile",
    reachCertificateFileExpires: "reachCertificateFileExpires",
    reachNotExpires: "reachNotExpires"
  }, 
  [CERTIFICATIONS]: {
    greenguard: "greenguard",
    greenguardLogo: "greenguardLogo",
    greenguardCertificateFile: "greenguardCertificateFile",
    greenguardCertificateFileExpires: "greenguardCertificateFileExpires",
    greenguardNotExpires: "greenguardNotExpires",
    indoorAirQuality: "indoorAirQuality",
    indoorAirQualityCertificateFile: "indoorAirQualityCertificateFile",
    indoorAirQualityCertificateFileExpires: "indoorAirQualityCertificateFileExpires",
    indoorAirQualityNotExpires: "indoorAirQualityNotExpires",
    indoorAirQualityLogo: "indoorAirQualityLogo",
    globalRecycledStandard: "globalRecycledStandard",
    globalRecycledStandardLogo: "globalRecycledStandardLogo",
    globalRecycledStandardCertificateFile: "globalRecycledStandardCertificateFile",
    globalRecycledStandardCertificateFileExpires: "globalRecycledStandardCertificateFileExpires",
    aPlusEmissions: "aPlusEmissions",
    aPlusEmissionsLogo: "aPlusEmissionsLogo",
    aPlusEmissionsCertificateFile: "aPlusEmissionsCertificateFile",
    aPlusEmissionsCertificateFileExpires: "aPlusEmissionsCertificateFileExpires",
    aPlusEmissionsNotExpires: "aPlusEmissionsNotExpires"
  },
  // This manufacturing object is necessary to add this group
  [MANUFACTURING]: {},
  [SEALS_WE_CONTRIBUTE_TO]: {
    leed: "leed",
    leedLogo: "leedLogo",
    well: "well",
    wellLogo: "wellLogo",
  },
  [PACKAGING]: {
    packagingFscLogo: "packagingFscLogo"
  }
}

