import {defineMessages} from "react-intl";

export const BACKING_DESCRIPTION_BASE_POLYESTER = 'Base Polyester';
export const BACKING_DESCRIPTION_BASE_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_BASE_POLYESTER]: {
    id: "BackingDescription.BasePolyester",
    description: "Backing description value named ",
    defaultMessage: "Base Polyester"
  }
});
export const BACKING_DESCRIPTION_BRUSHED_COTTON = 'Brushed Cotton';
export const BACKING_DESCRIPTION_BRUSHED_COTTON_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_BRUSHED_COTTON]: {
    id: "BackingDescription.brushedCotton",
    description: "Backing description value named ",
    defaultMessage: "Brushed Cotton"
  }
});
export const BACKING_DESCRIPTION_COTTON = 'Cotton';
export const BACKING_DESCRIPTION_COTTON_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_COTTON]: {
    id: "BackingDescription.cotton",
    description: "Backing description value named ",
    defaultMessage: "Cotton"
  }
});
export const BACKING_DESCRIPTION_CREPE = 'Crepe';
export const BACKING_DESCRIPTION_CREPE_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_CREPE]: {
    id: "BackingDescription.crepe",
    description: "Backing description value named ",
    defaultMessage: "Crepe"
  }
});
export const BACKING_DESCRIPTION_CREPE_HT = 'Crepe HT';
export const BACKING_DESCRIPTION_CREPE_HT_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_CREPE_HT]: {
    id: "BackingDescription.crepeHT",
    description: "Backing description value named ",
    defaultMessage: "Crepe HT"
  }
});
export const BACKING_DESCRIPTION_DYED_BASE_POLYESTER = 'Dyed Base Polyester';
export const BACKING_DESCRIPTION_DYED_BASE_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_DYED_BASE_POLYESTER]: {
    id: "BackingDescription.dyedBasePolyester",
    description: "Backing description value named ",
    defaultMessage: "Dyed Base Polyester"
  }
});
export const BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER = 'Dyed Standard Polyester';
export const BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER]: {
    id: "BackingDescription.dyedStandardPolyester",
    description: "Backing description value named ",
    defaultMessage: "Dyed Standard Polyester"
  }
});
export const BACKING_DESCRIPTION_ELBA_VALENCIA = 'Elba Valencia';
export const BACKING_DESCRIPTION_ELBA_VALENCIA_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_ELBA_VALENCIA]: {
    id: "BackingDescription.elbaValencia",
    description: "Backing description value named ",
    defaultMessage: "Elba Valencia"
  }
});
export const BACKING_DESCRIPTION_GLASS_FIBER = 'Glass Fiber';
export const BACKING_DESCRIPTION_GLASS_FIBER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_GLASS_FIBER]: {
    id: "BackingDescription.glassFiber",
    description: "Backing description value named ",
    defaultMessage: "Glass Fiber"
  }
});
export const BACKING_DESCRIPTION_HERRINGBONE = 'Herringbone';
export const BACKING_DESCRIPTION_HERRINGBONE_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HERRINGBONE]: {
    id: "BackingDescription.herringbone",
    description: "Backing description value named ",
    defaultMessage: "Herringbone"
  }
});
export const BACKING_DESCRIPTION_HI_LOFT = 'Hi-Loft';
export const BACKING_DESCRIPTION_HI_LOFT_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HI_LOFT]: {
    id: "BackingDescription.hi-Loft",
    description: "Backing description value named ",
    defaultMessage: "Hi-Loft"
  }
});
export const BACKING_DESCRIPTION_HILOFT_FLEX = 'Hiloft Flex';
export const BACKING_DESCRIPTION_HILOFT_FLEX_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HILOFT_FLEX]: {
    id: "BackingDescription.hiloftFlex",
    description: "Backing description value named ",
    defaultMessage: "Hiloft Flex"
  }
});
export const BACKING_DESCRIPTION_HI_LOFT_HW = 'Hi-Loft HW';
export const BACKING_DESCRIPTION_HI_LOFT_HW_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HI_LOFT_HW]: {
    id: "BackingDescription.hi-LoftHW",
    description: "Backing description value named ",
    defaultMessage: "Hi-Loft HW"
  }
});
export const BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON = 'Hi-Loft Poly/Cotton';
export const BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON]: {
    id: "BackingDescription.hi-LoftPoly/Cotton",
    description: "Backing description value named ",
    defaultMessage: "Hi-Loft Poly/Cotton"
  }
});
export const BACKING_DESCRIPTION_HI_LOFT_REGISTERED = 'Hi-Loft®';
export const BACKING_DESCRIPTION_HI_LOFT_REGISTERED_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_HI_LOFT_REGISTERED]: {
    id: "BackingDescription.hi-Loft®",
    description: "Backing description value named ",
    defaultMessage: "Hi-Loft®"
  }
});
export const BACKING_DESCRIPTION_JERRY = 'Jerry';
export const BACKING_DESCRIPTION_JERRY_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_JERRY]: {
    id: "BackingDescription.jerry",
    description: "Backing description value named ",
    defaultMessage: "Jerry"
  }
});
export const BACKING_DESCRIPTION_KEVLAR = 'Kevlar';
export const BACKING_DESCRIPTION_KEVLAR_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_KEVLAR]: {
    id: "BackingDescription.kevlar",
    description: "Backing description value named ",
    defaultMessage: "Kevlar"
  }
});
export const BACKING_DESCRIPTION_LW_HI_LOFT = 'LW Hi-Loft';
export const BACKING_DESCRIPTION_LW_HI_LOFT_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_LW_HI_LOFT]: {
    id: "BackingDescription.lWHi-Loft",
    description: "Backing description value named ",
    defaultMessage: "LW Hi-Loft"
  }
});
export const BACKING_DESCRIPTION_NYLON = 'Nylon';
export const BACKING_DESCRIPTION_NYLON_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_NYLON]: {
    id: "BackingDescription.nylon",
    description: "Backing description value named ",
    defaultMessage: "Nylon"
  }
});
export const BACKING_DESCRIPTION_POLY_TERRY = 'Poly Terry';
export const BACKING_DESCRIPTION_POLY_TERRY_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLY_TERRY]: {
    id: "BackingDescription.polyTerry",
    description: "Backing description value named ",
    defaultMessage: "Poly Terry"
  }
});
export const BACKING_DESCRIPTION_POLY_COTTON = 'Poly/Cotton';
export const BACKING_DESCRIPTION_POLY_COTTON_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLY_COTTON]: {
    id: "BackingDescription.poly/Cotton",
    description: "Backing description value named ",
    defaultMessage: "Poly/Cotton"
  }
});
export const BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT = 'Poly/Cotton Hi-Loft';
export const BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT]: {
    id: "BackingDescription.poly/CottonHi-Loft",
    description: "Backing description value named ",
    defaultMessage: "Poly/Cotton Hi-Loft"
  }
});
export const BACKING_DESCRIPTION_POLYESTER = 'Polyester';
export const BACKING_DESCRIPTION_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLYESTER]: {
    id: "BackingDescription.polyester",
    description: "Backing description value named ",
    defaultMessage: "Polyester"
  }
});
export const BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER = 'Polyester  - Glass Fiber';
export const BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER]: {
    id: "BackingDescription.polyesterGlassFiber",
    description: "Backing description value named ",
    defaultMessage: "Polyester  - Glass Fiber"
  }
});
export const BACKING_DESCRIPTION_POLYESTER_60 = 'Polyester 60';
export const BACKING_DESCRIPTION_POLYESTER_60_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLYESTER_60]: {
    id: "BackingDescription.polyester60",
    description: "Backing description value named ",
    defaultMessage: "Polyester 60"
  }
});
export const BACKING_DESCRIPTION_POLYESTER_CONDOR = 'Polyester Condor';
export const BACKING_DESCRIPTION_POLYESTER_CONDOR_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_POLYESTER_CONDOR]: {
    id: "BackingDescription.polyesterCondor",
    description: "Backing description value named ",
    defaultMessage: "Polyester Condor"
  }
});
export const BACKING_DESCRIPTION_SPUN_POLYESTER = 'Spun Polyester';
export const BACKING_DESCRIPTION_SPUN_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_SPUN_POLYESTER]: {
    id: "BackingDescription.spunPolyester",
    description: "Backing description value named ",
    defaultMessage: "Spun Polyester"
  }
});
export const BACKING_DESCRIPTION_STANDARD_POLYESTER = 'Standard Polyester';
export const BACKING_DESCRIPTION_STANDARD_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_STANDARD_POLYESTER]: {
    id: "BackingDescription.standardPolyester",
    description: "Backing description value named ",
    defaultMessage: "Standard Polyester"
  }
});
export const BACKING_DESCRIPTION_TRANSPORT_POLYESTER = 'Transport Polyester';
export const BACKING_DESCRIPTION_TRANSPORT_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_TRANSPORT_POLYESTER]: {
    id: "BackingDescription.transportPolyester",
    description: "Backing description value named ",
    defaultMessage: "Transport Polyester"
  }
});
export const BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER = 'Transport Spun Polyester';
export const BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER]: {
    id: "BackingDescription.transportSpunPolyester",
    description: "Backing description value named ",
    defaultMessage: "Transport Spun Polyester"
  }
});
export const BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER = 'Ultra Loft Polyester';
export const BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER_MESSAGE = defineMessages({
  [BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER]: {
    id: "BackingDescription.ultraLoftPolyester",
    description: "Backing description value named ",
    defaultMessage: "Ultra Loft Polyester"
  }
});

export const BACKING_DESCRIPTION = [
  {...BACKING_DESCRIPTION_BASE_POLYESTER_MESSAGE[BACKING_DESCRIPTION_BASE_POLYESTER], value: BACKING_DESCRIPTION_BASE_POLYESTER},
  {...BACKING_DESCRIPTION_BRUSHED_COTTON_MESSAGE[BACKING_DESCRIPTION_BRUSHED_COTTON], value: BACKING_DESCRIPTION_BRUSHED_COTTON},
  {...BACKING_DESCRIPTION_COTTON_MESSAGE[BACKING_DESCRIPTION_COTTON], value: BACKING_DESCRIPTION_COTTON},
  {...BACKING_DESCRIPTION_CREPE_MESSAGE[BACKING_DESCRIPTION_CREPE], value: BACKING_DESCRIPTION_CREPE},
  {...BACKING_DESCRIPTION_CREPE_HT_MESSAGE[BACKING_DESCRIPTION_CREPE_HT], value: BACKING_DESCRIPTION_CREPE_HT},
  {...BACKING_DESCRIPTION_DYED_BASE_POLYESTER_MESSAGE[BACKING_DESCRIPTION_DYED_BASE_POLYESTER], value: BACKING_DESCRIPTION_DYED_BASE_POLYESTER},
  {...BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER_MESSAGE[BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER], value: BACKING_DESCRIPTION_DYED_STANDARD_POLYESTER},
  {...BACKING_DESCRIPTION_ELBA_VALENCIA_MESSAGE[BACKING_DESCRIPTION_ELBA_VALENCIA], value: BACKING_DESCRIPTION_ELBA_VALENCIA},
  {...BACKING_DESCRIPTION_GLASS_FIBER_MESSAGE[BACKING_DESCRIPTION_GLASS_FIBER], value: BACKING_DESCRIPTION_GLASS_FIBER},
  {...BACKING_DESCRIPTION_HERRINGBONE_MESSAGE[BACKING_DESCRIPTION_HERRINGBONE], value: BACKING_DESCRIPTION_HERRINGBONE},
  {...BACKING_DESCRIPTION_HI_LOFT_MESSAGE[BACKING_DESCRIPTION_HI_LOFT], value: BACKING_DESCRIPTION_HI_LOFT},
  {...BACKING_DESCRIPTION_HILOFT_FLEX_MESSAGE[BACKING_DESCRIPTION_HILOFT_FLEX], value: BACKING_DESCRIPTION_HILOFT_FLEX},
  {...BACKING_DESCRIPTION_HI_LOFT_HW_MESSAGE[BACKING_DESCRIPTION_HI_LOFT_HW], value: BACKING_DESCRIPTION_HI_LOFT_HW},
  {...BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON_MESSAGE[BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON], value: BACKING_DESCRIPTION_HI_LOFT_POLY_COTTON},
  {...BACKING_DESCRIPTION_HI_LOFT_REGISTERED_MESSAGE[BACKING_DESCRIPTION_HI_LOFT_REGISTERED], value: BACKING_DESCRIPTION_HI_LOFT_REGISTERED},
  {...BACKING_DESCRIPTION_JERRY_MESSAGE[BACKING_DESCRIPTION_JERRY], value: BACKING_DESCRIPTION_JERRY},
  {...BACKING_DESCRIPTION_KEVLAR_MESSAGE[BACKING_DESCRIPTION_KEVLAR], value: BACKING_DESCRIPTION_KEVLAR},
  {...BACKING_DESCRIPTION_LW_HI_LOFT_MESSAGE[BACKING_DESCRIPTION_LW_HI_LOFT], value: BACKING_DESCRIPTION_LW_HI_LOFT},
  {...BACKING_DESCRIPTION_NYLON_MESSAGE[BACKING_DESCRIPTION_NYLON], value: BACKING_DESCRIPTION_NYLON},
  {...BACKING_DESCRIPTION_POLY_TERRY_MESSAGE[BACKING_DESCRIPTION_POLY_TERRY], value: BACKING_DESCRIPTION_POLY_TERRY},
  {...BACKING_DESCRIPTION_POLY_COTTON_MESSAGE[BACKING_DESCRIPTION_POLY_COTTON], value: BACKING_DESCRIPTION_POLY_COTTON},
  {...BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT_MESSAGE[BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT], value: BACKING_DESCRIPTION_POLY_COTTON_HI_LOFT},
  {...BACKING_DESCRIPTION_POLYESTER_MESSAGE[BACKING_DESCRIPTION_POLYESTER], value: BACKING_DESCRIPTION_POLYESTER},
  {...BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER_MESSAGE[BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER], value: BACKING_DESCRIPTION_POLYESTER_GLASS_FIBER},
  {...BACKING_DESCRIPTION_POLYESTER_60_MESSAGE[BACKING_DESCRIPTION_POLYESTER_60], value: BACKING_DESCRIPTION_POLYESTER_60},
  {...BACKING_DESCRIPTION_POLYESTER_CONDOR_MESSAGE[BACKING_DESCRIPTION_POLYESTER_CONDOR], value: BACKING_DESCRIPTION_POLYESTER_CONDOR},
  {...BACKING_DESCRIPTION_SPUN_POLYESTER_MESSAGE[BACKING_DESCRIPTION_SPUN_POLYESTER], value: BACKING_DESCRIPTION_SPUN_POLYESTER},
  {...BACKING_DESCRIPTION_STANDARD_POLYESTER_MESSAGE[BACKING_DESCRIPTION_STANDARD_POLYESTER], value: BACKING_DESCRIPTION_STANDARD_POLYESTER},
  {...BACKING_DESCRIPTION_TRANSPORT_POLYESTER_MESSAGE[BACKING_DESCRIPTION_TRANSPORT_POLYESTER], value: BACKING_DESCRIPTION_TRANSPORT_POLYESTER},
  {...BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER_MESSAGE[BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER], value: BACKING_DESCRIPTION_TRANSPORT_SPUN_POLYESTER},
  {...BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER_MESSAGE[BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER], value: BACKING_DESCRIPTION_ULTRA_LOFT_POLYESTER},
];
