import React, { useState } from "react";
import { Button } from "reactstrap";
import DownloadImagesModal from "./components/DownloadImagesModal";
import { FormattedMessage } from "react-intl";
import "./styles/DownloadImagesButton.scss";

const DownloadImagesButton = ({ projectSlug, zipImageFile }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleDownloadFiles = async () => {
    try {
      const response = await fetch(zipImageFile, {
        method: "GET",
        headers: {},
      });

      if (!response.ok) {
        console.log("HTTP error, status =", response.status);
        return;
      }

      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = `${projectSlug.trim()}-imgs.zip`;
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(objectUrl);
    } catch (e) {
      console.log("handleDownloadFiles HTTP error, e", e);
    }
  };

  return (
    <div className="col-12 p-5 text-center mt-lg-5 mb-lg-5">
      <Button
        className="font-weight-bold download--images_btn py-2 mx-auto px-md-5"
        onClick={() => setModalIsOpen(true)}
      >
        <span>
          <FormattedMessage id="DownloadZippedImagesButton.label" />
        </span>
      </Button>

      <DownloadImagesModal
        isOpen={modalIsOpen}
        toggle={handleCloseModal}
        handleDownload={handleDownloadFiles}
      />
    </div>
  );
};

export default DownloadImagesButton;
