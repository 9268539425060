import React from "react";
import rightArrow from "../img/right-arrow.png";
import leftArrow from "../img/left-arrow.png";

export const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  nextArrow: (
    <div>
      <div className="d-none d-md-block next-slick-arrow text-black pl-2">
        <img src={rightArrow} alt="right-arrow" width="40px" />
      </div>
    </div>
  ),
  prevArrow: (
    <div>
      <div className="d-none d-md-block prev-slick-arrow text-black">
        <img src={leftArrow} alt="left-arrow" width="40px" />
      </div>
    </div>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
  ],
};
