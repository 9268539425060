import { NOT_EXPIRES_TEXT } from "../components/ExpirationCertificate/constants";

/**
 * Calcula la diferencia en años y meses entre dos fechas.
 * @param {Date} date1 - La primera fecha.
 * @param {Date} date2 - La segunda fecha.
 * @returns {Object} - Un objeto con la diferencia en años y meses.
 */
const getYearMonthDifference = (date1, date2) => {
  let yearsDifference = date1.getFullYear() - date2.getFullYear();
  let monthsDifference = date1.getMonth() - date2.getMonth();

  if (monthsDifference < 0 || (monthsDifference === 0 && date2.getDate() < date1.getDate())) {
    yearsDifference--;
    monthsDifference += 12;
  }

  return {
    years: yearsDifference,
    months: monthsDifference
  };
}

/**
 * Calcula la fecha de expiración y la diferencia en años.
 * @param {string} expiresValue - La fecha de expiración en formato 'YYYY-MM-DD'.
 * @returns {Object} - Objeto con la fecha formateada y la diferencia de años hasta la expiración.
 */
export const calculateCertificateFileExpired = (expiresValue, notExpiresValue) => {
  if (!notExpiresValue && expiresValue) {
    const currentDate = new Date();
    
    const [year, month, day] = expiresValue.split("-");
    const formattedDate = new Date(`${year}-${month}-${day}`);
  
    const { years, months } = getYearMonthDifference(formattedDate, currentDate);
    
    const formattedExpiresDate = `${day}/${month}/${year}`;
  
    const remainingOneHalfYear = (years === 1 && months >= 6) || (years === 0 && months >= 18);
  
    return {
      formattedExpiresDate,
      timeRemainingUntilExpiration: years,
      remainingOneHalfYear,
      notExpiresValue
    };
  }

  return {
    formattedExpiresDate: NOT_EXPIRES_TEXT,
    timeRemainingUntilExpiration: 0,
    remainingOneHalfYear: 0,
    notExpiresValue
  };
}

export const assignLogoToUkcaAndMedProperties = (ruleUkca, ruleMed, ukcaLogo, medLogo) => {
  const assignUckaLogo = ruleUkca ? { ruleUkca: { logo: ukcaLogo, title: "ukca", code: "2923/2024" } } : null
  const assignMedLogo = ruleMed ? { ruleMed: { logo: medLogo, title: "med", code: "8512/2024" } } : null

  return { assignUckaLogo, assignMedLogo }
}