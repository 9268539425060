import React, { Component } from "react";
import classNames from "classnames/bind";
import "./styles/HeaderSearchBox.scss";
import { FormattedMessage } from "react-intl";
import { updateQueryParams } from "services/browser-history";
import { QUERY_PARAM_SEARCH } from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import proquinalHistory from "services/browser-history";

class HeaderSearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /**
   * Handles form's submit method to calls updateQueryParams and closeSearchBox
   * @param {Object} event
   */
  handleSubmit(event) {
    event.preventDefault();
    const { location } = this.props;
    const { searchValue } = this.state;

    this.setState({
      searchValue: "",
    });

    if (location.pathname === "/products/list")
      updateQueryParams({
        [QUERY_PARAM_SEARCH]: searchValue,
      });
    else
      proquinalHistory.push({
        pathname: "/products/list",
        search: `?${QUERY_PARAM_SEARCH}=${searchValue}`,
      });

    this.props.closeSearchBox();
  }

  /**
   * Handles input text changes and set value to searchValue state
   * @param event
   */
  handleInputChange(event) {
    this.setState({
      searchValue: event.target.value,
    });
  }

  render() {
    const { headerReducer } = this.props;
    const { searchValue } = this.state;

    return (
      <section
        className={classNames({
          "header-search-box": true,
          "header-search-box--visible": headerReducer.searchBoxIsOpen,
        })}
      >
        <div className="container">
          <form className="header-search-form" onSubmit={this.handleSubmit}>
            {headerReducer.searchBoxIsOpen && (
              <div className="input-group">
                <FormattedMessage
                  id="HeaderSearchBox.inputPlaceholder"
                  description="Placeholder for main search box"
                  defaultMessage="Search"
                >
                  {(placeholder) => (
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      value={searchValue}
                      onChange={this.handleInputChange}
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i onClick={this.handleSubmit} className="fa fa-search" />
                  </span>
                </div>
              </div>
            )}
          </form>
        </div>
      </section>
    );
  }
}

export default HeaderSearchBox;
