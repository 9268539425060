import React, { Component } from "react";
import "./styles/HeaderMainMenu.scss";
import classNames from "classnames/bind";
import MainMenuSections from "components/Header/components/HeaderMainMenu/components/MainMenuSections";
import MainMenuLocalesContainer from "components/Header/components/HeaderMainMenu/components/MainMenuLocales/container";
import MainMenuUserInfoContainer from "components/Header/components/HeaderMainMenu/components/MainMenuUserInfo/container";

class HeaderMainMenu extends Component {
  render() {
    const { headerReducer } = this.props;

    return (
      <div
        className={classNames({
          "header-main-menu": true,
          "header-main-menu--visible": headerReducer.mainMenuIsOpen,
        })}
      >
        <div className="main-menu-content">
          <section className="main-menu-content__sections">
            <MainMenuSections />
          </section>
          <section className="main-menu-content__locales-and-user">
            <MainMenuLocalesContainer />
            <MainMenuUserInfoContainer />
          </section>
        </div>
      </div>
    );
  }
}

export default HeaderMainMenu;
