import React, { useEffect, useState } from 'react'
import { INSTANCES_TO_SKIP_AND_NOT_SHOW } from '../constants';
import { CATEGORIES } from '../../../constants';
import { handleMixInstancesAccordingCode } from '../constants/handleMixInstancesByRegion';
import { OPTIONS_LABEL_FOR_INSTANCES } from 'scenes/SearchProjects/components/ProjectsByCategory/constants/categoriesOptionsToSearch';

export const useFilterOptions = (options, name) => {
  const [filterOptions, setFilterOptions] = useState(options);

	// NOTE - Return the result instances ids mix according the current option clicked by user
	const handleMixInstances = (currentOption) => {
		const resultMixUsaInstances = handleMixInstancesAccordingCode(options, currentOption, OPTIONS_LABEL_FOR_INSTANCES[1].key);
		const resultMixLatamInstances = handleMixInstancesAccordingCode(options, currentOption, OPTIONS_LABEL_FOR_INSTANCES[0].key);

		const resultInstancesGroup = 
			currentOption.code === OPTIONS_LABEL_FOR_INSTANCES[0].key ? resultMixLatamInstances : resultMixUsaInstances

		return resultInstancesGroup;
	}

	// NOTE - filter options to not show marine, resources and mexico instances in the business filter
	useEffect(() => {
    if (name === CATEGORIES[1].name) {
      const filteredOptions = options?.filter((option) => {
        return !INSTANCES_TO_SKIP_AND_NOT_SHOW.some((instance) => {
          return option.code === instance;
        })
      })

      setFilterOptions(filteredOptions);
    }
  }, [])

  return { filterOptions, handleMixInstances }
}
