import React from "react";
import "./styles/DownloadImagesSuccessfully.scss";
import { FormattedMessage } from "react-intl";

const ProjectDetailsDownloadImagesSuccessfully = () => {
  return (
    <div className="container">
      <div className="p-md-5">
        <div className="p-4 p-md-5 mt-5 mb-5 text-secondary donwload--successfully_container">
          <h2 className="font-weight-bold text-center pb-2 donwload--successfully_title">
            <FormattedMessage
              id="DownloadProjectImagesSuccessfully.Title"
              description="Downloaded succesfully!"
              defaultMessage="Downloaded succesfully!"
            />
          </h2>
          <hr className="mt-4 border-secondary donwload--successfully_divider" />
          <h4 className="text-center px-lg-5 py-3 col-11 mx-auto donwload--successfully_paragraph">
            <FormattedMessage
              id="DownloadProjectImagesSuccessfully.Description"
              description="Downloaded succesfully Description"
              defaultMessage="<sup>©</sup> All rights reserved. This content is exclusively for internal use by employees of the Spradling<sup>®</sup>Group. The intellectual property associated with it is owned or has been authorized by companies belonging to the Spradling<sup>®</sup> business group. Reproduction, downloading, and use outside of this library are prohibited without authorization from the Central Marketing Department."
              values={{
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
            />
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsDownloadImagesSuccessfully;
