import gql from "graphql-tag";

export const referencesQuery = gql`
  query references_search {
    referencesAvailableForProjectSearch {
      id
      name
    }
  }
`;

export const businessUnitsQuery = gql`
  query business_units_search {
    businessUnitAvailableForProjectSearch {
      id
      name
      code
    }
  }
`;

export const citiesQuery = gql`
  query cities_search {
    citiesAvailableForProjectSearch
  }
`;

export const countriesQuery = gql`
  query countries_search {
    countryAvailableForProjectSearch {
      id
      name
    }
  }
`;

export const segmentsQuery = gql`
  query market_segments_projects($lang: String) {
    projectSegments(lang: $lang) {
      value
      title
    }
  }
`;
