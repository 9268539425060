/**
 * Retrieves the current filter parameter based on the formatted parameters, location, and intl object.
 * @param {Object} formattedParams - The formatted parameters object.
 * @param {Object} location - The location object.
 * @param {Object} intl - The intl object.
 * @returns {string} The current filter parameter.
 */
export const getCurrentFilterParam = (formattedParams, location, intl) => {
  let currentParam = ""

  Object.entries(formattedParams).forEach(([, value]) => {
    if (!location.state) return currentParam = value

    const transformLocationState = JSON.parse(location.state)
    if (transformLocationState) {
      const { 
        businessData = null, 
        marketSegmentData = null,
        searchByTexture = null
      } = transformLocationState

      let currentTitle;

      if (businessData) {
        const { instanceLabelTitle = null } = businessData
        currentTitle = instanceLabelTitle;
      }

      if (marketSegmentData) {
        const { segmentTitle = null } = marketSegmentData
        currentTitle = segmentTitle;
      }

      if (searchByTexture) {
        const { textureTitle = null } = searchByTexture
        currentTitle = textureTitle;
      }

      currentParam = intl.formatMessage({ id: currentTitle.props.id })
    }
  })

  return currentParam
}