import React from "react";
import { FormattedMessage } from "react-intl";
import "./styles/HomeMarketSegments.scss";
import { MARKET_SEGMENTS } from "scenes/ProductsPage/components/FiltersBar/contants/market_segments";
import { QUERY_PARAM_SEGMENT } from "scenes/ProductsPage/components/FiltersBar/contants/query_params";
import { Link } from "react-router-dom";
import proquinalHistory from "services/browser-history";

const HomeMarketSegments = (props) => {
  /**
   * Handle select change and redirect to products page with selected segment as query param
   * @param event
   * @param event.target
   * @param event.target.value
   */
  const handleSelectChange = (event) => {
    proquinalHistory.push({
      pathname: `/products/list`,
      search: `?${QUERY_PARAM_SEGMENT}=${event.target.value}`,
    });
  };

  return (
    <div className="home-market-segments">
      <h2 className="home-market-segments__title">
        <FormattedMessage
          id="HomeMarketSegments.title"
          description="Title for the home's market segments section"
          defaultMessage="Search by markets"
        />
      </h2>
      <hr className="home-market-segments__separator" />
      <p className="home-market-segments__text font-weight-light">
        <FormattedMessage
          id="HomeMarketSegments.text"
          description="Text below the title at home's market segments section"
          defaultMessage="Select the market where you want to look for products and discover all the alternatives we have for it"
        />
      </p>
      <div className="home-market-segments__mobile-selector">
        <form>
          <div className="form-group">
            <select
              className="form-control text-capitalize"
              onChange={handleSelectChange}
            >
              <FormattedMessage
                id="HomeMarketSegments.selectPlaceholder"
                description="Default value for mobile select at home's market segments section"
                defaultMessage="Select segment"
              >
                {(defaultValue) => <option>{defaultValue}</option>}
              </FormattedMessage>
              {MARKET_SEGMENTS.map((item, index) => (
                <FormattedMessage
                  key={`homeMarketSegmentOption${index}`}
                  {...item}
                >
                  {(defaultValue) => (
                    <option value={item.value}>{defaultValue}</option>
                  )}
                </FormattedMessage>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className="home-market-segments__desktop-list">
        <div className="row">
          {MARKET_SEGMENTS.map((item, index) => (
            <div
              key={`homeMarketSegment${index}`}
              className="market-segment col-sm-6"
            >
              <Link
                to={{
                  pathname: `/products/list`,
                  search: `?${QUERY_PARAM_SEGMENT}=${item.value}`,
                }}
              >
                <h5 className="market-segment__title">
                  <FormattedMessage {...item} />
                </h5>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeMarketSegments;
