import React from "react";
import { FormattedMessage } from "react-intl";

export const REASONS_TO_USE_PROJECT = [
  {
    id: 1,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.SocialMedia"
      defaultMessage="Social Media"
    />
  },
  {
    id: 2,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.E-mailMarketing"
      defaultMessage="E-mail Marketing"
    />
  },
  {
    id: 3,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.PrintMaterial"
      defaultMessage="Print material"
    />
  },
  {
    id: 4,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.SalesPresentation"
      defaultMessage="Sales presentation"
    />
  },
  {
    id: 5,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.FairsOrEvents"
      defaultMessage="Fairs or events"
    />
  },
  {
    id: 6,
    reason: <FormattedMessage 
      id="DownloadProjectImagesModal.InternalUse"
      defaultMessage="Internal use"
    />
  }
]