import React, { Component } from "react";
import Header from "components/Header";
import classNames from "classnames/bind";
import "./styles/ProquinalApp.scss";
import Home from "scenes/Home";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ProductsPage from "scenes/ProductsPage";
import ProductDetails from "scenes/ProductDetails";
import ComparePage from "scenes/ComparePage";
import LoginPageContainer from "scenes/Auth/scenes/LoginPage/container/index";
import ForgotPasswordPage from "scenes/Auth/scenes/ForgotPasswordPage";
import ResetPasswordPage from "scenes/Auth/scenes/ResetPasswordPage";
import ProductPyramid from "scenes/ProductPyramid";
import FAQ from "scenes/FAQ";
import FooterContainer from "components/Footer/container";
import Warranty from "scenes/Warranty";
import NewHomeLibrary from "../scenes/NewHomeLibrary";
import SearchProjects from "../scenes/SearchProjects";
import SearchProjectsByCategory from "../scenes/SearchProjectsByCategory";
import ProjectDetails from "../scenes/ProjectDetails";
import ProjectDetailsDownloadImagesSuccessfully from "../scenes/ProjectDetails/components/ProjectDetailsDownloadImagesSuccessfully";

class ProquinalLibraryApp extends Component {
  get onAuthLocation() {
    const authLocationRegexp = /\/auth\/\w+/;
    return authLocationRegexp.test(this.props.location.pathname);
  }

  render() {
    const { authReducer } = this.props;
    const { isAuthenticated } = authReducer;

    return (
      <div
        className={classNames({
          "proquinal-app": true,
          "proquinal-app--with-header": !this.onAuthLocation,
        })}
      >
        {!this.onAuthLocation && <Header />}
        <Route exact path="/auth/login" component={LoginPageContainer} />
        <Route
          exact
          path="/auth/forgot-password"
          component={ForgotPasswordPage}
        />
        <Route
          exact
          path="/auth/reset-password/:uid/:token"
          component={ResetPasswordPage}
        />
        {isAuthenticated ? (
          <Switch>
            <Route exact path="/" component={NewHomeLibrary} />
            <Route exact path="/products" component={Home} />
            <Route exact path="/products/list" component={ProductsPage} />
            <Route exact path="/products/compare" component={ComparePage} />
            <Route
              exact
              path="/products/:productSlug"
              component={ProductDetails}
            />
            <Route exact path="/product-pyramid" component={ProductPyramid} />
            <Route exact path="/projects" component={SearchProjects} />
            <Route
              exact
              path="/projects/list"
              component={SearchProjectsByCategory}
            />
            <Route
              exact
              path="/project/detail/:project"
              component={ProjectDetails}
            />
            <Route
              exact
              path="/project/detail/download/success"
              component={ProjectDetailsDownloadImagesSuccessfully}
            />
            <Route exact path="/documents/warranty" component={Warranty} />
            <Route exact path="/help/faq" component={FAQ} />
          </Switch>
        ) : (
          <React.Fragment>
            {!this.onAuthLocation && <Redirect to="/auth/login" />}
          </React.Fragment>
        )}
        <FooterContainer />
      </div>
    );
  }
}

export default withRouter(ProquinalLibraryApp);
