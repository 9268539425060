import React from 'react'
import SpecsTableColumn from '../../../SpecsTableRow/components/SpecsTableColumn';
import CertificateLink from '../CertificateLink';
import { orderRowInformation } from './constants/orderRowInfo';
import classNames from 'classnames';

const MobileInfoRows = ({ currentItemInfo }) => {
  const { resultInfo, haveCertificateFile } = orderRowInformation(currentItemInfo);
  
  return (
    <>
      <tr>
        {resultInfo.map((item, index) => {
          return <td key={index} 
          colSpan={item.colSpanValue} 
          className={classNames({
            'align-middle': true,
            "pr-0": haveCertificateFile
          })}
          >
            {haveCertificateFile && index === 1 ? (
              <SpecsTableColumn value={<CertificateLink link={item.value} isMobile />} />
            ) : (
              <SpecsTableColumn value={item.value} />
            )}
          </td>
        })}
      </tr>
    </>
  )
}

export default MobileInfoRows