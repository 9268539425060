import React from "react"
import { FormattedMessage } from "react-intl"
import { MANUFACTURING, SUSTAINABILITY_ATTRIBUTES } from "./attributesByGroups"
import { MASIALA_SLUG, RIVULET, VALENCIA_BIOSENSE_SLUG } from "./draftSpecificProducts"

export const separeDataByGroups = (data, manufacturingCertifications, packagingFsc, slug) => {
  const certificateFileString = "CertificateFile"
  const expiresString = "Expires"
  const notExpiresString = "Not"

  const specificProductsToShowSustainabilityProfile = [
    MASIALA_SLUG,
    VALENCIA_BIOSENSE_SLUG,
    RIVULET
  ]

  const SUSTAINABILITY_GROUPS = [
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="attributes"
        id="FiltersBar.attributesFilterTitle"
        description="Title for Attributes filter section at filters bar"
        defaultMessage="Attributes" />,
      attrs: []
    },
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="certifications"
        id="FiltersBar.certificationsFilterTitle"
        description="Title for Certificates filter section at filters bar"
        defaultMessage="Certificates" />,
      attrs: [],
      areCertificates: true
    },
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="seals"
        id="FiltersBar.sealsWeContributeFilterTitle"
        description="Title for Seals we contribute to filter section at filters bar"
        defaultMessage="Seals we contribute to" />,
      attrs: [],
      areCertificates: true
    },
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="manufacturing"
        id="FiltersBar.manufacturingFilterTitle"
        description="Title for packaging filter section at filters bar"
        defaultMessage="Sustainable manufacturing certifications" />,
      attrs: [],
      areCertificates: true
    },
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="compliances"
        id="FiltersBar.compliancesFilterTitle"
        description="Title for Compliances filter section at filters bar"
        defaultMessage="Compliances" />,
      attrs: [],
      areCertificates: true
    },
    {
      id: crypto.randomUUID(),
      title: <FormattedMessage
        key="packaging"
        id="FiltersBar.packagingFilterTitle"
        description="Title for packaging filter section at filters bar"
        defaultMessage="Packaging" />,
      attrs: [],
      areCertificates: true
    }
  ]

  const addAttrsInGeneral = (key, value) => {
    let keyCondition =
      !key.includes(certificateFileString) &&
      !key.includes("Not") &&
      key !== "id" &&
      key !== "__typename"

    if (keyCondition) {
      SUSTAINABILITY_GROUPS.map((group) => {
        if (SUSTAINABILITY_ATTRIBUTES[group.title.key].hasOwnProperty(key) && value !== null && value !== "" && value !== false) {
          if (!key.includes("Logo")) {
            group.attrs.push({
              id: crypto.randomUUID(),
              title: key,
              value: value,
              certificate: ""
            })
          }

          if (key === SUSTAINABILITY_ATTRIBUTES["packaging"].packagingFscLogo && packagingFsc) {
            group.attrs.push({
              id: crypto.randomUUID(),
              title: key,
              value: value,
              certificate: ""
            })
          }
        }
      })
    }
  }

  const addAttrsLogos = (key, value) => {
    if (key.includes("Logo")) {
      SUSTAINABILITY_GROUPS.map((group) => {
        if (SUSTAINABILITY_ATTRIBUTES[group.title.key].hasOwnProperty(key)) {
          group.attrs.filter((property) => {
            if (key.includes(property.title)) {
              property.logoImg = value
            }
          })
        }
      })
    }
  }

  const addCertificates = (key, value) => {
    // Add certificate value to the current attribute
    if (key.includes(certificateFileString) && !key.includes(expiresString)) {
      SUSTAINABILITY_GROUPS.map((group) => {
        if (SUSTAINABILITY_ATTRIBUTES[group.title.key].hasOwnProperty(key)) {
          group.attrs.filter((property) => {
            if (key.includes(property.title)) {
              property.certificate = value
            }
          })
        }
      })
    }

    // Add expire certificate value to the current attribute
    if (key.includes(expiresString) && !key.includes(notExpiresString)) {
      SUSTAINABILITY_GROUPS.map((group) => {
        if (SUSTAINABILITY_ATTRIBUTES[group.title.key].hasOwnProperty(key)) {
          group.attrs.filter((property) => {
            if (key.includes(property.title)) {
              property.certificateExpiresDate = value
            }
          })
        }
      })
    }

    // Add NOT expire certificate value to the current attribute
    if (!key.includes(certificateFileString) && key.includes(notExpiresString)) {
      SUSTAINABILITY_GROUPS.map((group) => {
        if (SUSTAINABILITY_ATTRIBUTES[group.title.key].hasOwnProperty(key)) {
          group.attrs.filter((property) => {
            if (key.includes(property.title)) {
              property.certificateNotExpires = value
            }
          })
        }
      })
    }
  }

  const addManufacturingCertifications = (sustainabilityGroups, manufacturingCertifications) => {
    manufacturingCertifications.map((property) => {
      if (property.isSustainable) {
        sustainabilityGroups.map((group) => {
          if (group.title.key === MANUFACTURING) {
            group.attrs.push({
              id: property.id,
              title: property.name,
              logoImg: property.logo,
              certificate: ""
            })
          }
        })
      }
    })
  }

  Object.entries(data).forEach(([key, value]) => {
    addAttrsInGeneral(key, value)
    addAttrsLogos(key, value)
    addCertificates(key, value)
  });

  addManufacturingCertifications(SUSTAINABILITY_GROUPS, manufacturingCertifications);

  const filteredGroups = SUSTAINABILITY_GROUPS.filter(group =>
    group.attrs.length > 0
  );
  return filteredGroups
}