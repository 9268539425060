import React, { Component } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { renderInput } from "utils/form/renderers";
import { required } from "utils/form/validators";
import { Link } from "react-router-dom";
import { renderCheckbox } from "utils/form/renderers";

export class LoginForm extends Component {
  render() {
    const { errors } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-group">
          <label htmlFor="email" className="sr-only">
            <FormattedMessage
              id="LoginForm.emailLabel"
              description="Label for email input at login form"
              defaultMessage="Email" />
          </label>
          <FormattedMessage
            id="LoginForm.emailPlaceholder"
            description="Placeholder for email input at login form"
            defaultMessage="Corporate email address">
            {
              placeholder => (
                <Field
                  name="email"
                  id="email"
                  component={renderInput}
                  type="email"
                  placeholder={placeholder}
                  validate={[required]}
                  className="form-control bg-transparent form-login-input" />
              )
            }
          </FormattedMessage>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="sr-only">
            <FormattedMessage
              id="LoginForm.passwordLabel"
              description="Label for password input at login form"
              defaultMessage="Password" />
          </label>
          <FormattedMessage
            id="LoginForm.passwordPlaceholder"
            description="Placeholder for password input at login form"
            defaultMessage="Your password">
            {
              placeholder => (
                <Field
                  name="password"
                  id="password"
                  component={renderInput}
                  type="password"
                  placeholder={placeholder}
                  validate={[required]}
                  className="form-control bg-transparent form-login-input" />
              )
            }
          </FormattedMessage>
        </div>
        {
          errors.map((error, index) => (
            <p key={`formError${index}`}>
              <strong>
                {error}
              </strong>
            </p>
          ))
        }
        <p className="mb-0">
          <FormattedMessage
            id="LoginForm.disclaimerTop"
            description="Top disclaimer text at login form"
            defaultMessage="Before logging in, you must accept our" />
        </p>
        <div className="form-group form-check">
          <Field
            name="termsAndConditions"
            id="terms-and-conditions"
            component={renderCheckbox}
            type="checkbox"
            className="form-check-input" />
          <label
            onClick={this.props.openTermsModal}
            className="form-check-label text-white">
            <FormattedMessage
              id="LoginForm.disclaimerBottom"
              description="Bottom disclaimer text at login form"
              defaultMessage="Terms and Conditions" />
          </label>
        </div>
        <p className="form-text text-muted mb-4 mt-4">
          <Link to="/auth/forgot-password">
            <FormattedMessage
              id="LoginForm.forgotPassword"
              description="Forgot password text below password input at login form"
              defaultMessage="Forgot your password?" />
          </Link>
        </p>
        <div className="form-group mb-4 pt-2">
          <button type="submit" className="btn btn-outline-light btn-lg py-1 px-4" style={{ fontWeight: "bold" }}>
            <FormattedMessage
              id="LoginForm.submitButton"
              description="Submit button label at login form"
              defaultMessage="Log In" />
          </button>
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

const LoginReduxForm = reduxForm({
  form: 'LoginForm'
})(LoginForm);


export default LoginReduxForm;