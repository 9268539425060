import React, { useState } from "react";
import { Query } from "react-apollo";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import ProjectCard from "./components/ProjectCard";
import "./styles/projects-list.scss";
import { projectsQuery } from "./query";
import LoadingMessage from "utils/components/LoadingMessage";


const ProjectsList = ({ filters }) => {

  const [haveEdges, setHaveEdges] = useState(null)

  return (
    <div className="custom_class_project_list container py-3 px-0">
      <Query query={projectsQuery} variables={filters}>
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <div className="text-center col-12 pt-3 pb-5">
                <LoadingMessage />
              </div>
            )
          }
  
          if (!data?.promotedProjects?.edges?.length && !loading && error)
            return (
              <p className="w-100 my-5 h4 text-danger text-center">
                <FormattedMessage
                  id="Projects.List.noData"
                  defaultMessage="We couldn't find any results for your search. Try exploring the categories below."
                />
              </p>
            );
  
          const promotedProjects = data?.promotedProjects?.edges || [];
          setHaveEdges(promotedProjects)
  
          return <>
          {
            promotedProjects.length > 0 ?
            promotedProjects?.map((project, i) => { 
              if (!project) return null
              return (
                <ProjectCard
                  key={`item_${project.node.id}`}
                  islargeCard={i % 5 === 3}
                  {...project.node}
                />
              )
            })
            :
            <p className="w-100 my-5 h4 text-danger text-center">
              <FormattedMessage
                id="Projects.List.noDataFilter"
                defaultMessage="No results. Try adjusting the filters."
              />
            </p>
          }
          </>
        }}
      </Query>
      <div className="col-12 text-center projects-category__btn-view-projects mb-5">
        {
          haveEdges && haveEdges.length > 0 &&
          <a href="/projects">
            <Button className="font-weight-bold home-button-more-info pt-1 pb-1 col-md-2 mx-auto">
              <FormattedMessage id="Projects.List.btn" defaultMessage="View more" />
            </Button>
          </a>
        }
      </div>
    </div>
  )
}

export default ProjectsList;
