import { FormattedMessage } from "react-intl";
import React from "react"

export const NOT_EXPIRES_TEXT = (
  <FormattedMessage 
    id="ProductSpecAccordionBody.tooltip.notExpires"
    defaultMessage="Not Expire"
  />
)

export const EXPIRED_MESSAGE = (expiredData, expiredMessage, onlyCircleWithoutText) => {
  
  const expiredDateContent = onlyCircleWithoutText ? (
    <span className="d-inline-block w-100 text-center">
      <strong>{expiredData.formattedExpiresDate}</strong><br />
    </span>
  ) : null;

  const EXPIRED_MESSAGE_ACCORDING_REMAINING_TIME = {
    littleTimeToExpires: (
      <FormattedMessage 
        id="ProductSpecAccordionBody.tooltip.littleTimeToExpires"
        defaultMessage="{expiredDate}<strong>Red color:</strong> References that are close to expiring in 1 years"
        values={{
          strong: (...chunks) => <strong>{chunks}</strong>,
          expiredDate: expiredDateContent
        }}
      />
    ),
    goodTimeToExpires: (
      <FormattedMessage 
        id="ProductSpecAccordionBody.tooltip.goodTimeToExpires"
        defaultMessage="{expiredDate}<strong>Green color:</strong> References that are close to expiring in 2 years"
        values={{
          strong: (...chunks) => <strong>{chunks}</strong>,
          expiredDate: expiredDateContent
        }}
      />
    ),
    mediumTimeToExpires: (
      <FormattedMessage 
        id="ProductSpecAccordionBody.tooltip.mediumTimeToExpires"
        defaultMessage="{expiredDate}<strong>Orange color:</strong> References that are close to expiring in 1 year and a half"
        values={{
          strong: (...chunks) => <strong>{chunks}</strong>,
          expiredDate: expiredDateContent
        }}
      />
    ),
    notExpires: (
      <FormattedMessage 
        id="ProductSpecAccordionBody.tooltip.notExpiredCertificate"
        defaultMessage="<strong>Gray color:</strong> Certificate without expiration date"
        values={{
          strong: (...chunks) => <strong>{chunks}</strong>
        }}
      />
    )
  }

  return EXPIRED_MESSAGE_ACCORDING_REMAINING_TIME[expiredMessage]
}