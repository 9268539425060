import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FLAME_RETARDANCY_NO_VALUE } from "scenes/ProductDetails/components/ProductSpecs/contants";
import PdfResultRow from "./components/PdfResultRow";
import { classifyFlameRetardancyExpiresDate } from "./constants";
import { calculateCertificateFileExpired } from "../OtherAttributesAccordion/constants";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "../OtherAttributesAccordion/constants/breakpoints";
import ExpirationCertificate from "./components/ExpirationCertificate";

const FlameRetardancyAccordion = ({ data }) => {
  const isMobile = useMediaQuery(LG);

  return data.map(({ test, pdf, result, expiration_date }) => {
    let certificateExpiresElement;
    
    const { expirationDate, notExpires } = classifyFlameRetardancyExpiresDate(expiration_date);
    
    if (expirationDate || notExpires) {
      const resultExpiresValue = calculateCertificateFileExpired(expirationDate, notExpires);
      certificateExpiresElement = <ExpirationCertificate expiredData={resultExpiresValue} onlyCircleWithoutText={isMobile} />
    }

    return <tr
      key={`key_${test}`}
      className={classNames({
        "flame-retardancy--pdf": pdf !== FLAME_RETARDANCY_NO_VALUE,
      })}
    >
      <td colSpan={pdf === FLAME_RETARDANCY_NO_VALUE && !result ? 2 : 0}>
        {pdf !== FLAME_RETARDANCY_NO_VALUE ? (
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            {test}
          </a>
        ) : (
          test
        )}
      </td>
      <PdfResultRow pdf={pdf} result={result} />
      <td>{certificateExpiresElement}</td>
    </tr>
  });
} 

FlameRetardancyAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.string,
      pdf: PropTypes.string,
      result: PropTypes.string,
    })
  ).isRequired,
};

export default FlameRetardancyAccordion;
