import React from "react";
import ProjectCard from "../SearchProjectsByCategory/components/ProjectsList/components/ProjectCard";
import { SETTINGS } from "./constants/settingsSlider";
import Slider from "react-slick";
import DownloadImagesButton from "./components/DownloadImagesButton";
import ProjectDetailsHeaderInfo from "./components/ProjectDetailsHeaderInfo";
import imageSliderProjectDetail1 from "./img/1.jpg";
import { Query } from "react-apollo";
import { projectQuery } from "./query";
import { FormattedMessage, useIntl } from "react-intl";
import CTICImage from "./img/CTIC_Banner.jpg";
import imageDefaultDesktop from "./img/ImageDefaultDesktop.png";
import backArrow from "./img/backArrow.svg";
import "./styles/ProjectDetails.scss";
import SliderProductDetail from "./components/SliderProductDetail/SliderProductDetail";
import { STATIC_SPRADLING_DOMAIN } from "scenes/SearchProjectsByCategory/components/ProjectsList/components/ProjectCard/constants/staticSpradlingDomain";

const ProjectDetails = ({ match }) => {
  const { project: slug } = match.params;
  const { locale } = useIntl();
  console.log('🚀🚀🚀  > > > > ProjectDetails > > > > locale:', locale);

  const ID_TO_NAME_INSTANCE = {
    "SW5zdGFuY2VOb2RlOjQ=": locale === 'es' ? 'Europa' : 'Europe',
    "SW5zdGFuY2VOb2RlOjE=": locale === 'es' ? 'Latinoamérica' : 'Latin America',
    "SW5zdGFuY2VOb2RlOjM=": locale === 'es' ? 'Estados Unidos' : 'United States',
  };

  return (
    <Query query={projectQuery} variables={{ slug }}>
      {({ loading, data, error }) => {
        const promotedProject = data?.promotedProject;
        const relatedProjects = promotedProject?.relatedProjects;

        return (
          <>
            <div className="d-flex pl-2 pt-3 mt-lg-5 container_back_button">
              <img src={backArrow || ""} alt="Arrow back" onClick={()=> window.history.back()} />
              <p className="m-0" onClick={()=> window.history.back()}>Regresar</p>
            </div>
            <div className="d-flex flex-column flex-lg-row pt-lg-3 mt-lg-5 project-details--img-content">
              <div className="d-flex justify-content-center overflow-hidden project-details--image-lrg-container col-lg-6 p-0 col-12 order-2 order-lg-0 pr-3 pr-lg-0">
                <img
                  className="img-fluid project-details--master-img"
                  src={ promotedProject?.image ?
                    `${STATIC_SPRADLING_DOMAIN}/${promotedProject?.image}`
                    : imageDefaultDesktop
                  }
                  alt="ctic-banner"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="py-5 text-secondary col-12 col-lg-6 order-1 order-lg-0">
                <ProjectDetailsHeaderInfo {...promotedProject} />
              </div>
            </div>

            <div className="container mt-3 mt-lg-5 p-0">
              <hr className="text-lg-center px-4 text-secondary project-details__description text-black border-dark mt-5 mb-5 mt-lg-0 mb-lg-0" />

              <div className="d-lg-flex mt-3 mt-lg-5 p-0">
                <div
                  className="d-flex justify-content-center align-items-center col-lg-6 p-0 pl-3 pl-lg-0 pr-3 pr-lg-0"
                  // style={{ height: "400px" }}
                >


                  <SliderProductDetail dataProject={ promotedProject } />
                  {/* <img
                    src={promotedProject?.images[0] || imageDefaultDesktop}
                    alt="ctic-details"
                    className="w-100 h-100 img-ctic-details"
                  /> */}



                </div>
                <div className="custom_class_container_col_data_detail col-lg-6 px-4 mt-3 mt-lg-0 p-lg-5 text-secondary">
                  <h3 className="project-details__descriptions">
                    <FormattedMessage
                      id="ProjectDetails.Manufacturers"
                      defaultMessage="<strong>Manufacturer/A&D:</strong> {projectManufactures}"
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        projectManufactures: promotedProject?.manufacturer?.map((item, idx) => {
                          const addComa = idx !== promotedProject.manufacturer.length - 1 ? ", " : "";

                          return item + addComa
                        })
                      }}
                    />
                  </h3>
                  <h4 className="project-details__descriptions">
                    <FormattedMessage
                      id="ProjectDetails.Application"
                      defaultMessage="<strong>Application: </strong>{application}"
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        application: locale === "en" ? promotedProject?.applicationEn : promotedProject?.applicationEs
                      }}
                    />
                  </h4>
                  <h3 className="project-details__descriptions">
                    <FormattedMessage
                      id="ProjectDetails.Bussiness"
                      defaultMessage="<strong>Business unit:</strong> {projectInstances}"
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        projectInstances: promotedProject?.instances?.edges.map(({ node }, idx) => {
                          const addComa = idx !== promotedProject.instances.edges.length - 1 ? ", " : "";
                          // console.log('🚀🚀🚀  > > > > projectInstances:promotedProject?.instances?.edges.map > > > > node:', node);

                          const actualUnitName = ID_TO_NAME_INSTANCE[node?.id]

                          return `${actualUnitName} ${addComa}`
                        })
                      }}
                    />
                  </h3>
                  <h4 className="project-details__descriptions">
                    <FormattedMessage
                      id="ProjectDetails.Year"
                      defaultMessage="<strong>Year:</strong> {projectYear}"
                      values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        projectYear: promotedProject?.year
                      }}
                    />
                  </h4>
                </div>
              </div>


              {/* <div className="mt-3 p-0 pr-3 pr-lg-0">
                <Slider {...SETTINGS} className="p-0">
                  {promotedProject?.images.map((image, idx) => (
                    <div key={crypto.randomUUID()} className="p-0 design-sector-img">
                      <img
                        className="p-0 pr-2  design-sector-img"
                        src={image}
                        alt={`promoted-project-image-${idx + 1}`}
                      />
                    </div>
                  ))}
                </Slider>
              </div> */}


            </div>

            <DownloadImagesButton
              projectSlug={promotedProject?.slug}
              zipImageFile={promotedProject?.zippedImages}
            />

            {relatedProjects?.edges?.length !== 0 &&
              <>
                <div className="container text-secondary">
                  <h1 className="project-details__title-related-projects">
                    <FormattedMessage
                      id="ProjectDetails.RelatedProjects"
                      description="Projects related to the same product"
                      defaultMessage="Projects related to the same product"
                    />
                  </h1>

                  <div className="d-flex flex-wrap overflow-hidden realted-projects--height">
                    {relatedProjects?.edges?.map((project, idx) => {
                      if (idx <= 2) {
                        return (
                          <ProjectCard
                            key={`item_${project.node.title}`}
                            {...project.node}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              </>
            }
          </>
        );
      }}
    </Query>
  );
};

export default ProjectDetails;
