import React from "react";
import SearchProjectsHeader from "./components/SearchProjectsHeader";
import ProjectsByCategory from "./components/ProjectsByCategory";

const SearchProjects = () => (
  <>
    <SearchProjectsHeader />
    <ProjectsByCategory />
  </>
);

export default SearchProjects;
