import React from "react";
import { FormattedMessage } from "react-intl";

const APPEARANCE_VALUES = {
  LEATHER: "LEATHER",
  TEXTILE: "TEXTILE",
  GEOMETRIC: "GEOMETRIC",
  WOOD_TYPE: "WOOD TYPE",
  SUEDE_TYPE: "SUEDE TYPE",
  PLAIN: "PLAIN",
  SATIN: "SATIN",
  DOES_NOT_APPLY: "DOES NOT APPLY",
  PENDING_TEXTURE: "PENDING TEXTURE",
};

export const APPEARANCES = [
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.leather"
        key="leather"
        defaultMessage="Leather type"
      />
    ),
    value: APPEARANCE_VALUES.LEATHER,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.textile"
        key="textile"
        defaultMessage="Textile type"
      />
    ),
    value: APPEARANCE_VALUES.TEXTILE,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.geometric"
        key="geometric"
        defaultMessage="Geometric type"
      />
    ),
    value: APPEARANCE_VALUES.GEOMETRIC,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.wood"
        key="wood"
        defaultMessage="Wood type"
      />
    ),
    value: APPEARANCE_VALUES.WOOD_TYPE,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.suede"
        key="suade"
        defaultMessage="Suede type"
      />
    ),
    value: APPEARANCE_VALUES.SUEDE_TYPE,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.plain"
        key="plain"
        defaultMessage="Plain"
      />
    ),
    value: APPEARANCE_VALUES.PLAIN,
  },
  {
    label: (
      <FormattedMessage
        id="Projects.Home.category.texture.option.satin"
        key="satin"
        defaultMessage="Satin"
      />
    ),
    value: APPEARANCE_VALUES.SATIN,
  },
  // {
  //   label: APPEARANCE_VALUES.DOES_NOT_APPLY,
  //   value: APPEARANCE_VALUES.DOES_NOT_APPLY,
  // },
  // {
  //   label: APPEARANCE_VALUES.PENDING_TEXTURE,
  //   value: APPEARANCE_VALUES.PENDING_TEXTURE,
  // },
];
