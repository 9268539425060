import gql from "graphql-tag";

export const projectsQuery = gql`
  query projects(
    $products: [ID]
    $instances: [ID]
    $countries: [ID]
    $authorizations: [ID]
    $marketSegments: [String]
    $lang: String
    $keyword: String
  ) {
    promotedProjects(
      products: $products
      instances: $instances
      countries: $countries
      authorizations: $authorizations
      marketSegments: $marketSegments
      lang: $lang
      keyword: $keyword
    ) {
      edges {
        node {
          id
          slug
          title
          image
          marketSegment
          products {
            edges {
              node {
                id
                name
              }
            }
          }
          city {
            id
            name
          }
        }
      }
    }
  }
`;
