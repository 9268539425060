import { MARKET_SEGMENTS } from "scenes/SearchProjects/components/ProjectsByCategory/constants/markets"

const TOTAL_AUTHORIZATION = "TOTAL_AUTHORIZATION"
const WITHOUT_AUTHORIZATION = "NO_AUTHORIZATION"
const ONLY_INTERN_USE = "FOR_INTERNAL_USE_ONLY"

export const AUTHORIZATION_PROJECT = {
  TOTAL_AUTHORIZATION,
  WITHOUT_AUTHORIZATION,
  ONLY_INTERN_USE
}

export const getMarketSegmentTitle = (marketSegment) => {
  const findedMarketSegment = marketSegment && MARKET_SEGMENTS.find(segment => segment.value === marketSegment.replace(/_/g, ' '))
  return findedMarketSegment
}