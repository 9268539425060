import React from "react";
import { FormattedMessage } from "react-intl";

const patternClassPl = <FormattedMessage
  key="PL"
  id="ProductHeader.patternClass.pl"
  defaultMessage="Line product"
  values={{
    br: <br />
  }}
/>

const patternClassDS = <FormattedMessage
  key="DS"
  id="ProductHeader.patternClass.ds"
  defaultMessage="Discontinued"
/>

const patternClassEX = <FormattedMessage
  key="EX"
  id="ProductHeader.patternClass.ex"
  defaultMessage="Exclusive"
/>

const patternClassCP = <FormattedMessage
  key="CP"
  id="ProductHeader.patternClass.cp"
  defaultMessage="Personalized"
/>

export const PRODUCT_CLASSES = {
  [patternClassPl.key]: patternClassPl,
  [patternClassDS.key]: patternClassDS,
  [patternClassEX.key]: patternClassEX,
  [patternClassCP.key]: patternClassCP
}