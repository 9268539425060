import React, { useMemo, useState } from 'react'
import { Tooltip } from 'reactstrap';
import "./styles/ReferencesPatternClass.scss";
import { PRODUCT_CLASSES } from './constants';

let idCounter = 0;

const ReferencesPatternClass = ({ patternClass }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Generate a unique ID for the tooltip
  const tooltipId = useMemo(() => `patternClassTooltip_${idCounter++}`, []);

  const toggle = (value) => setTooltipOpen(value);

  return (
    <div
      className="reference-card__pattern-class shadow"
      id={tooltipId}
      onMouseOver={() => toggle(true)}
      onMouseLeave={() => toggle(false)}
    >
      {patternClass}
      <i
        className="fa fa-info-circle reference-card__pattern-class-info"
        id={tooltipId}
        onMouseOver={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
      />
      <Tooltip
        key={tooltipId}
        placement={"top"}
        isOpen={tooltipOpen}
        target={tooltipId}
        className='references-pattern-class--tooltip'
      >
        {PRODUCT_CLASSES[patternClass]}
      </Tooltip>
    </div>
  )
}

export default ReferencesPatternClass