import gql from 'graphql-tag';

const singlePatternSustainabilityAttributesFragment = gql`
    fragment SinglePatternSustainabilityAttributesFragment on SustainabilityNode {
        id
        biobasedPvcBioAttributed
        phthalateFreeProduct
        phthalateFreeProductCertificateFile
        phthalateFreeProductCertificateFileExpires
        phthalateFreeProductNotExpires
        frFree
        frFreeCertificateFile
        frFreeCertificateFileExpires
        frFreeNotExpires
        mpFree
        mpFreeCertificateFile
        mpFreeCertificateFileExpires
        mpFreeNotExpires
        cytotoxicity
        cytotoxicityCertificateFile
        cytotoxicityCertificateFileExpires
        cytotoxicityNotExpires
        skinSafeSensitization
        skinSafeSensitizationCertificateFile
        skinSafeIrritation
        skinSafeIrritationCertificateFile
        skinSafeSensitizationCertificateFileExpires
        skinSafeSensitizationNotExpires
        antimicrobial
        backingProfile
        backingProfileLogo
        prop65
        prop65Logo
        prop65CertificateFileExpires
        prop65CertificateFile
        prop65NotExpires
        rohs
        rohsLogo
        rohsCertificateFile
        rohsCertificateFileExpires
        rohsNotExpires
        reach
        reachLogo
        reachCertificateFile
        reachCertificateFileExpires
        reachNotExpires
        greenguard
        greenguardLogo
        greenguardCertificateFile
        greenguardCertificateFileExpires
        greenguardNotExpires
        indoorAirQuality
        indoorAirQualityCertificateFile
        indoorAirQualityCertificateFileExpires
        indoorAirQualityNotExpires
        indoorAirQualityLogo
        globalRecycledStandard
        globalRecycledStandardLogo
        globalRecycledStandardCertificateFile
        globalRecycledStandardCertificateFileExpires
        globalRecycledStandardNotExpires
        aPlusEmissions
        aPlusEmissionsLogo
        aPlusEmissionsCertificateFile
        aPlusEmissionsCertificateFileExpires
        aPlusEmissionsNotExpires
        epd
        epdCertificateFile
        epdNotExpires
        leed
        leedLogo
        well
        wellLogo
        packagingFscLogo
    }
`;

export default singlePatternSustainabilityAttributesFragment;