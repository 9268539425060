
export const classifyFlameRetardancyExpiresDate = (expirationDate) => {
  const expiresValueIsEmpty = expirationDate.includes("not set");
  const notExpiresValue = expirationDate.includes("not expire");

  if (expiresValueIsEmpty) {
    expirationDate = ""
    return { expirationDate, notExpires: false }
  }

  if (notExpiresValue) {
    expirationDate = ""
    return { expirationDate, notExpires: true }
  }

  return { expirationDate, notExpiresValue: false }
}