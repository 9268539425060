import React from "react";
import spradlingLogoLandscape from "./img/spradling-logo-landscape.svg";
import spradlingLogoPortrait from "./img/spradling-logo-portrait.svg";
import "./styles/HeaderLogo.scss";

const HeaderLogo = (props) => (
  <a className="header-logo" href="/">
    <img
      src={spradlingLogoLandscape}
      alt="Spradling"
      className="header-logo__image header-logo__image--landscape"
    />
    <img
      src={spradlingLogoPortrait}
      alt="Spradling"
      className="header-logo__image header-logo__image--portrait"
    />
  </a>
);

export default HeaderLogo;
