export const LOGO_PERMABLOK = 'Permablok';
export const LOGO_PERMABLOK_3 = 'Permablok 3';
export const LOGO_PERMABLOK_PLUS = 'Permablok Plus';
export const LOGO_PERMABLOK_3_PLUS = 'Permablok 3 Plus';
export const LOGO_PERMAGUARD = 'Permaguard';
export const LOGO_PERMACOOL = 'Permacool';
export const LOGO_SILVERGUARD = 'SILVERGUARD';
export const LOGO_SUREGUARD = 'Sureguard';
export const LOGO_PBG = 'PBG';
export const LOGO_PBG_3 = 'PBG 3';
export const LOGO_HI_LOFT = 'Hi-Loft®';
export const LOGO_HI_LOFT_FLEX = 'Hiloft Flex';
export const LOGO_ACRYLIC = 'Acrylic';