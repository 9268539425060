import React from "react";

import { useIntl } from "react-intl";
import "scenes/ProductDetails/components/ProductSpecs/components/GeneralAccordion/styles/general-accordion.scss";

const CertificateLink = ({ link, isMobile }) => {
  const intl = useIntl();
  const text = intl.formatMessage({
    id: "ProductSpecAccordionBody.certificate",
  });
  if (link === "") return <p></p>;
  return (
    <a
      className="d-inline ml-3 text-dark font-weight-bold"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-download" />
      {!isMobile && <span className="ml-2">{text}</span>}
    </a>
  );
};

export default CertificateLink;
