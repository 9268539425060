import React from "react";
import searchBySegmentImage from "../img/Segment.jpg";
import searchByBussinessUnit from "../img/bussines_unit.png";
import searchByTexture from "../img/Texture.jpg";
import { FormattedMessage } from "react-intl";
import { instancesQuery } from "../query";
import { MARKET_SEGMENTS } from "./markets";
import { APPEARANCES } from "./appearance";
import { handleMixInstancesAccordingCode } from "scenes/SearchProjectsByCategory/components/CategoryFilter/components/CategoryAccordion/constants/handleMixInstancesByRegion";

export const OPTIONS_LABEL_FOR_INSTANCES = [
  {
    key: "la",
    label: (
      <FormattedMessage
        id="Projects.Home.category.bussines.option.latam"
        defaultMessage="Latin America"
      />
    ),
  },
  {
    key: "us",
    label: (
      <FormattedMessage
        id="Projects.Home.category.bussines.option.usa"
        defaultMessage="United States"
      />
    ),
  },
  {
    key: "eu",
    label: (
      <FormattedMessage
        id="Projects.Home.category.bussines.option.europe"
        defaultMessage="Europe"
      />
    ),
  },
];

export const CATEGORIES_OPTIONS_TO_SEARCH = [
  {
    img: searchBySegmentImage,
    searchBy: (
      <FormattedMessage
        id="Projects.Home.category.segment.title"
        key="segment"
        defaultMessage="Search by segment"
      />
    ),
    urlParam: "market_segment",
    optionsQuery: "",
    options: MARKET_SEGMENTS,
  },
  {
    id: "instances",
    img: searchByBussinessUnit,
    searchBy: (
      <FormattedMessage
        key="business"
        id="Projects.Home.category.bussines.title"
        defaultMessage="Search by business unit"
      />
    ),
    urlParam: "bussines",
    optionsQuery: instancesQuery,
    handleOptions: ({ edges }) => {
      return edges.map(({ node }) => {
        const usaInstances = handleMixInstancesAccordingCode(edges, node, OPTIONS_LABEL_FOR_INSTANCES[1].key);
        const latamInstances = handleMixInstancesAccordingCode(edges, node, OPTIONS_LABEL_FOR_INSTANCES[0].key);
        
        const matchingOption = OPTIONS_LABEL_FOR_INSTANCES.find(
          (option) => option.key === node.code
        );

        const determineValue = (node, usaInstances, latamInstances) => {
          const usaKey = OPTIONS_LABEL_FOR_INSTANCES[1].key;
          const latamKey = OPTIONS_LABEL_FOR_INSTANCES[0].key;
        
          switch (node.code) {
            case usaKey:
              return JSON.stringify(usaInstances);
            case latamKey:
              return JSON.stringify(latamInstances);
            default:
              return node.id;
          }
        };
        return {
          label: matchingOption ? matchingOption.label : "",
          value: determineValue(node, usaInstances, latamInstances)
        };
      });
    },
  },
  {
    img: searchByTexture,
    searchBy: (
      <FormattedMessage
        id="Projects.Home.category.texture.title"
        key="texture"
        defaultMessage="Search by texture"
      />
    ),
    urlParam: "keyword",
    options: APPEARANCES,
  },
];
