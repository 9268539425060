import gql from "graphql-tag";

export const instancesQuery = gql`
  query instancesQuery {
    instances {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;
