import React, { useState, useEffect, useRef } from "react";
import defaultImg from "./img/ImageDefaultDesktop.png";
import "./styles/SliderProductDetail.scss";
import IconDownload from "./img/IconDownload.svg";
import useMediaQuery from "utils/hooks/useMediaQuery";
import { LG } from "scenes/ProductDetails/components/ProductSpecs/components/OtherAttributesAccordion/constants/breakpoints";

const SliderProductDetail = ({ dataProject }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery(LG);
  const slideInterval = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % dataProject?.images?.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? dataProject?.images?.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleDownloadImagesByClickInBtn = () => {
    const btnDownloadInDOM = document.querySelector(".download--images_btn");

    if (btnDownloadInDOM) {
      btnDownloadInDOM.click();
    }
  };

  const resetSlideInterval = () => {
    if (slideInterval.current) {
      clearInterval(slideInterval.current);
    }
    slideInterval.current = setInterval(nextSlide, 5000);
  };

  useEffect(() => {
    resetSlideInterval();

    return () => {
      if (slideInterval.current) {
        clearInterval(slideInterval.current);
      }
    };
  }, [dataProject?.images]);

  const handleUserInteraction = (action) => {
    resetSlideInterval();
    action();
  };

  return (
    <>
      {dataProject?.images?.length > 0 ? (
        <div className="slider-container">
          {/* Slider principal */}
          <div
            className="slider"
            style={{
              transform: `translate${isMobile ? "X" : "Y"}(-${currentIndex * 100}%)`,
            }}
          >
            {dataProject?.images?.map((src, index) => (
              <div
                key={index}
                className={`slide ${index === currentIndex ? "active" : ""}`}
              >
                <img src={src} alt={`Slide ${index}`} />
              </div>
            ))}
          </div>

          {/* Botón de descarga */}
          <div
            className="container_icon_download_in_slider"
            onClick={handleDownloadImagesByClickInBtn}
          >
            <img src={IconDownload} alt="download" />
          </div>

          {/* Botones de navegación */}
          <button
            className="prev-btn"
            onClick={() => handleUserInteraction(prevSlide)}
          >
            &#10094;
          </button>
          <button
            className="next-btn"
            onClick={() => handleUserInteraction(nextSlide)}
          >
            &#10095;
          </button>

          {/* Navegación por puntos */}
          <div className="container_dots">
            {dataProject?.images?.map((_, index) => (
              <button
                key={index}
                className={`dotSliderProductDetail ${index === currentIndex ? "active" : ""}`}
                onClick={() => handleUserInteraction(() => goToSlide(index))}
              ></button>
            ))}
          </div>

          {/* Miniaturas */}
          <div className="thumbnails">
            {dataProject?.images?.map((src, index) => (
              <button
                key={index}
                onClick={() => handleUserInteraction(() => goToSlide(index))}
                className={`thumbnail ${currentIndex === index ? "active" : ""}`}
              >
                <img src={src} alt={`Thumbnail ${index}`} />
              </button>
            ))}
          </div>
        </div>
      ) : (
        <img src={defaultImg} alt="default" className="img_default" />
      )}
    </>
  );
};

export default SliderProductDetail;
