
export const orderRowInformation = (currentItemInfo) => {
  const firstPairInfo = currentItemInfo.slice(0, 2);
  const secondPairInfo = currentItemInfo.slice(2, 4);

  const haveCertificateFile = secondPairInfo[1] !== ""

  const rowWithCertificateInfo = [
    {
      value: firstPairInfo[0],
      colSpanValue: 8
    },
    {
      value: secondPairInfo[1],
      colSpanValue: 4
    },
    {
      value: firstPairInfo[1],
      colSpanValue: 8
    },
    {
      value: secondPairInfo[0],
      colSpanValue: 6
    }
  ]

  const rowWithoutCertificateInfo = [
    {
      value: firstPairInfo[0],
      colSpanValue: 12
    },
    {
      value: firstPairInfo[1],
      colSpanValue: 14
    }
  ]

  const resultInfo = haveCertificateFile ? rowWithCertificateInfo : rowWithoutCertificateInfo
  return { resultInfo, haveCertificateFile }

}