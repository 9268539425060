import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "./styles/ProjectCategoryItem.scss";
import { CATEGORIES_OPTIONS_TO_SEARCH } from "../../constants/categoriesOptionsToSearch";
import { handleStateByUrlParam } from "./constants";

const ProjectCategoryItem = ({ img, urlParam, searchBy, options, imgLeft }) => {
  return (
    <div className="row no-gutters my-4 my-lg-0" key={`item_${searchBy}`}>
      <div
        className={classNames({
          "d-flex col-12 col-lg-6 p- border border-2": true,
          "order-1": !imgLeft,
          "order-2": imgLeft,
        })}
      >
        <img src={img} className="img-fluid" alt="" style={{ width: "100vw" }} />
      </div>
      <div
        className={classNames({
          "col-12 col-lg-6 d-flex align-items-center px-md-4": true,
          "order-1 order-lg-2": !imgLeft,
          "order-2 order-lg-1": imgLeft,
        })}
      >
        <div className="mt-4 my-lg-3 my-lg-0 px-md-5 d-flex justify-content-center">
          <div className="px-5">
            <h2 className="font-weight-bold text-secondary mb-3 project-catergory-item__title ">
              {searchBy}
            </h2>
            <div className="d-flex flex-wrap m-1">
              {options &&
                options.map(({ label, value, icon }) => 
                  label && (
                  <div key={`key_${value}`} className="py-2 col-6 px-0">
                    {icon && (
                      <img
                        src={icon}
                        width={25}
                        className="p-0 mr-2 ml-0"
                        alt="element-category"
                      />
                    )}
                    <Link
                      className="project--category_option"
                      id={`item_${value}`}
                      to={{
                        pathname: `/projects/list`,
                        search: `?${urlParam}=${value}`,
                        state: handleStateByUrlParam(urlParam, label)
                      }}
                    >
                      <span className="py-2">{label}</span>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProjectCategoryItem;
